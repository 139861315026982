
























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DniField from "@/components/DniField.vue";
import { mapState } from "vuex";
import API from "@/api";
import { EventBus } from "@/main";
import { Configuration } from "../configuration";
import { Uri } from "@/utils";

@Component({
  computed: mapState(["user"]),
  data() {
    return {
      form: {
        dni: "",
        password: "",
      },
      canPress: true,
      isWaitingForLogin: false,
      dialog: false,
      loggedinSuccesfully: false,
      showPassword: false,
      redirect: null,
      error: null,
      isButtonClickable: false,
      businessData: [],
      siiConfig:{
        vincular:false,
        certificado:false
      },
      //   tgr:false,
      //   previred:false,
      //   notify:{
      //     mail:false,
      //     whatsapp: true,
      //     oferta:true,
      //   }
      // },
      rules: {
        password: [(v: any) => !!v || "La contraseña es requerida"],
      },
      tipo:null,
    };
  },
  watch: {
    form: {
      handler(val) {
        // @ts-ignore
        if (this.$refs.login.validate()) {
          this.$data.isButtonClickable = true;
        } else {
          this.$data.isButtonClickable = false;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.$data.canPress = true;
    EventBus.$on("open-login-dialog", (path: string) => {
      if (path != undefined) {
        this.$data.redirect = Uri.string(path);
      }
      this.$data.dialog = true;
      this.$data.error = null;
    });
  },
  beforeRouteEnter(to, from,next) {
    next(vm => {
      if(from.fullPath == '/solicitar'){
        vm.$data.tipo=3;
      }else {
        vm.$data.tipo=2;
      }
    });
  },
  methods: {
    async onSubmit() {
      this.$data.canPress = false;
      this.$data.isButtonClickable = true;
      this.$data.error = null;

      API.login(this.$data.form.dni, this.$data.form.password)
        .then((data: any) => {
          this.$store.commit("setUser", data.usuario);
          this.$store.commit("setToken", data.access_token);
          this.$store.commit("setExpireTime", data.expires_at);
          this.$data.dialog = false;
          setTimeout(() => {
            this.$data.loggedinSuccesfully = true;
            setTimeout(() => {
              this.$data.loggedinSuccesfully = false;
            }, Configuration.Redirection.timeout);
          }, 500);
          EventBus.$emit("login-successfully");
          setTimeout(() => {
            if (this.$data.redirect != null) {
              if (this.$data.redirect.indexOf("/") == 0) {
                this.$router.push({
                  path: `${this.$data.redirect}`,
                });
              } else {
                this.$router.push({
                  path: `/${this.$data.redirect}`,
                });
              }
            }  else {
              if(data.usuario.user_tipo_id==3){
                  this.$router.push({
                    path: '/panel-facto/estadisticas'
                  })
                }else{
                  this.$router.push({
                    path: '/panel/estadisticas'
                  })
                }
            }
          }, 500);
          this.$data.canPress = true;
        })
        .catch((error) => {
          this.$data.canPress = true;
          this.$data.error = "Usuario y/o contraseña incorrecta";
          EventBus.$emit("snack-error", this.$data.error);
        })
        .finally(() => {
          this.$data.isButtonClickable = true;
        });
    },
    //  business(){
    //       API.getBusiness2(this.$store.state.user.id)
    //       .then((res:any)=>{
    //         this.$data.businessData = res
    //           if(this.$data.businessData.length == 0){
    //             
    //             this.$router.push('panel-facto/empresas');
    //             setTimeout(() => {
    //               EventBus.$emit("empresa", true);
    //             },1000)
    //           }
    //           if(this.$data.businessData.length == 1){
    //             
    //             this.$store.commit('setBusinessSelected', this.$data.businessData[0])
              
    //           API.factoring.get_cuentas(this.$data.businessData[0].id)
    //           .then((res:any)=>{
    //             
    //           })
    //           .catch((err:any)=>{
    //             
    //           })
    //           API.factoring.get_cuenta_sii(this.$data.businessData[0].rut)
    //           .then((res:any)=>{
    //             
    //           })
    //           .catch((err:any)=>{
    //             
    //           })
    //           API.factoring.get_cuenta_previred(this.$data.businessData[0].rut)
    //           .then((res:any)=>{
    //             
    //           })
    //           .catch((err:any)=>{
    //             
    //           })
    //           API.factoring.get_cuenta_tgr(this.$data.businessData[0].rut)
    //           .then((res:any)=>{
    //             
    //           })
    //           .catch((err:any)=>{
    //             
    //           }) 
    //       } 
    //       if(this.$data.businessData.length > 1){
    //         setTimeout(() => {
    //           EventBus.$emit("multibusiness", true);
    //         },1000)
    //       }
    //       })
    // },
    dialogWasCancel() {
      this.$data.dialog = false;
      this.$data.canPress = true;
      EventBus.$emit("login-dialog-cancelled");
    },
  },
  components: {
    DniField,
  },
})
export default class Login extends Vue {}
